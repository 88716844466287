import SpeakerNotesOffIcon from "@mui/icons-material/SpeakerNotesOff";
import { Avatar, Box, Chip, CircularProgress, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { initChat } from "../../../../helper/APIs/USER/Chat";
import {
  CalenderIcon,
  LocationSVGIcon,
  RemoteIcon,
  WhatsappIcon,
} from "../../../../shared/Constants/icons";
import BecomeTasker from "../../../../shared/Navbar/NewHeader/BecomeTasker/becomeTasker";
import LoginPopUp from "../../Modals/LoginPopUp";
import BecomeHelperNotifyPopup from "./BecomeHelperNotify";
import "./listing.css";
import TaskImagePreview from "./taskImagePreview";
import { getTimeString } from "../../../../shared/Constants/constants";
import MoreInfoInputIcon from "../../../../shared/InputFieldsWithLabels/MoreInfoInputIcon";

const TaskListing = ({ loading }) => {
  const navigate = useNavigate();
  const listingData = useSelector(
    (state) => state.listingReducer.listingListData
  );
  const profileInfo = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );

  const isLoggedIn = useSelector((state) => state.loginReducer.isLoggedIn);

  const [listing, setListing] = useState(listingData);
  const [openLoginPopUp, setOpenLoginPopUp] = useState(false);
  const [taskContent, setTaskContent] = useState({});
  const [isBecomeHelperNotify, setIsBecomeHelperNotify] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [isBecomeHelperForm, setIsBecomeHelperForm] = useState(false);
  const [mediaPreview, setMediaPreview] = useState({
    index: null,
    action: false,
  });

  const [isKeen, setIsKeen] = useState(false);
  const storedAddress = JSON.parse(localStorage.getItem("address")) || "";

  useEffect(() => {
    let previousWidth = window.innerWidth;

    const handleResize = () => {
      const currentWidth = window.innerWidth;

      // Only act if the width has changed
      if (currentWidth !== previousWidth) {
        if (currentWidth >= 991.98) {
          setTaskContent(listing[0]); // Set content only if the width is larger than 991.98
        } else {
          setTaskContent({});
        }
      }

      // Update the previousWidth for future resize events
      previousWidth = currentWidth;
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [listing]);

  useEffect(() => {
    if (listingData) {
      setListing(listingData);
      if (window.innerWidth >= 991.98) {
        setTaskContent(listingData[0]);
      }
    }
  }, [listingData]);

  const handleAuth = () => {
    if (isLoggedIn) {
      if (!profileInfo.isUserActiveHelper) {
        setIsBecomeHelperNotify(true);
      } else {
        setIsKeen(true);
      }
    } else {
      setOpenLoginPopUp(true);
    }
  };

  const handleSamaylekhaChat = () => {
    let submitData = { listingInfo: { listingId: taskContent.id } };
    initChat(submitData)
      .then(async (res) => {
        if (res) {
          navigate(`/chat/${res?.data?.id}`);
        }
      })
      .catch((err) => console.error("err", err));
  };

  const isAssignedPost = (taskContent) => {
    return taskContent?.listingStatus === "ASSIGNED";
   };
   
  const isCompletedPost = (taskContent) => {
    return taskContent?.listingStatus === "COMPLETED";
   };
  
   const isUserOwnPost = (taskContent) => {
   return taskContent?.ownerUserProfile?.userProfileId === profileInfo?.id;
  };

  return (
    <Box className="container" sx={{ marginTop: "60px" }}>
      {loading && (
        <div style={{ margin: "2rem 0rem" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        </div>
      )}
      {!loading && isEmpty(listing) && (
        <Box
          className="d-flex justify-content-center align-items-center full-width flex-column"
          sx={{
            height: "85vh",
          }}
        >
          <SpeakerNotesOffIcon />
          <p> No Tasks Available </p>
        </Box>
      )}

      {!loading && !isEmpty(listing) && (
        <Box className="task-listing-wrapper d-flex justify-content-start position-relative">
          {!loading && (
            <Box className="task-list d-flex flex-column" sx={{ gap: "10px" }}>
              {!loading &&
                !isEmpty(listing) &&
                listing.map((item, index) => {
                  return (
                    <Box
                      key={index}
                      className={`task-item d-flex flex-column justify-content-between ${
                        item.id === taskContent?.id ? "active-task" : ""
                      }`}
                      onClick={() => {
                        setTaskContent(item);
                        setShowPhone(false);
                        setIsKeen(false);
                      }}
                    >
                      <Box className="d-flex align-items-center justify-content-between">
                        <Typography
                          className="truncate"
                          sx={{
                            maxWidth: "70%",
                            width: "100%",
                            color: "#283350",
                            fontWeight: "600",
                          }}
                        >
                          {item.title}
                        </Typography>
                        {item?.budget ? (
                          <Typography
                            sx={{
                              maxWidth: "30%",
                              width: "100%",
                              color: "#444",
                              textAlign: "right",
                              fontWeight: "600",
                            }}
                          >
                            € {item?.budget}
                            {item?.budgetType === "PER_HOUR" && "/h"}
                          </Typography>
                        ) : (
                          <Chip label={"Offer"} color={"success"} />
                        )}
                      </Box>
                      <Box className="mt--10 d-flex flex-column align-items-start">
                        <Typography
                          component={"span"}
                          fontSize={14}
                          className="d-flex justify-content-center align-items-center"
                          sx={{
                            gap: "10px",
                          }}
                        >
                          {item.locationType === "IN_PERSON" ? (
                            <LocationSVGIcon color={"#9BA0BC"} size="16" />
                          ) : (
                            <RemoteIcon />
                          )}{" "}
                          {item.locationType === "IN_PERSON"
                            ? item?.listingLocation
                              ? `${item?.listingLocation?.city?.cityName}, ${item?.listingLocation?.city?.country?.countryName}`
                              : storedAddress
                            : "Remote"}
                        </Typography>
                        <Typography
                          component={"span"}
                          fontSize={14}
                          className="d-flex justify-content-center align-items-center"
                          sx={{
                            gap: "10px",
                            marginTop: "4px",
                          }}
                        >
                          {" "}
                          <CalenderIcon color={"#9BA0BC"} size="16" />{" "}
                          {["ON_DATE", "BEFORE_DATE"].includes(
                            item.taskDueDateType
                          )
                            ? `On ${moment(item?.taskDueDate).format(
                                "ddd, MMM DD"
                              )}`
                            : "Flexible"}
                        </Typography>
                      </Box>
                      <Box className="d-flex justify-content-between align-items-center">
                        <Typography
                          component={"h4"}
                          fontSize="14px"
                          sx={{
                            color: "#283350",
                            textTransform: "capitalize",
                            fontWeight: "500",
                          }}
                        >
                          {item.listingStatus}
                        </Typography>
                        <Avatar
                          sx={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                          variant="square"
                          alt={item?.ownerUserProfile?.userDisplayName}
                          src={item?.ownerUserProfile?.userProfileImageUrl}
                        />
                      </Box>
                    </Box>
                  );
                })}
            </Box>
          )}

          {!loading && !isEmpty(listing) && (
            <Box className="task-descriptions">
              {!isEmpty(taskContent) && (
                <Box
                  className="p-2 d-flex"
                  gap={1}
                  sx={{ padding: "16px 20px !important" }}
                >
                  <Box className="task-details">
                    <Box className="d-flex align-items-center" gap={1}>
                      {["OPEN", "ASSIGNED", "COMPLETED"].map((chip, index) => (
                        <Chip
                          key={index}
                          label={chip}
                          color={
                            taskContent.listingStatus === chip
                              ? "success"
                              : "default"
                          }
                        />
                      ))}
                    </Box>
                    <Typography
                      mt={2}
                      fontSize={28}
                      sx={{
                        color: "#283350",
                        fontWeight: "600",
                      }}
                    >
                      {taskContent.title}
                    </Typography>
                    <Box className="d-flex justify-content-between align-items-center mt--20">
                      <Box className="d-flex align-items-center" gap={1}>
                        <Box>
                          <Avatar
                            sx={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            variant="square"
                            alt={taskContent?.ownerUserProfile?.userDisplayName}
                            src={
                              taskContent?.ownerUserProfile?.userProfileImageUrl
                            }
                          />
                        </Box>
                        <Box>
                          <Typography fontSize={12} fontWeight={"medium"}>
                            POSTED BY
                          </Typography>
                          <Typography
                            fontSize={12}
                            sx={{ color: "#283350", fontWeight: "500" }}
                          >
                            {taskContent?.ownerUserProfile?.userDisplayName}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography fontSize={12}>
                          {getTimeString(taskContent.createTime)}
                        </Typography>
                      </Box>
                    </Box>
                    {taskContent.listingLocation?.locationPlaceId && (
                      <>
                        <Box className="mt--20 d-flex" gap={1}>
                          <Box
                            sx={{ minWidth: "40px" }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <LocationSVGIcon size={"30"} color={"#283350"} />
                          </Box>
                          <Box>
                            <Typography fontSize={12} fontWeight={"medium"}>
                              LOCATION
                            </Typography>
                            <Typography
                              fontSize={12}
                              sx={{ color: "#283350", fontWeight: "500" }}
                            >
                              {taskContent.locationType === "IN_PERSON"
                                ? `${taskContent?.listingLocation?.locationPlaceId}`
                                : "Remote"}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                    <Box className="mt--20 d-flex" gap={1}>
                      <Box
                        sx={{ minWidth: "40px" }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <CalenderIcon size={"30"} color={"#283350"} />
                      </Box>
                      <Box>
                        <Typography fontSize={12} fontWeight={"medium"}>
                          TO BE DONE ON
                        </Typography>
                        <Typography
                          fontSize={12}
                          sx={{ color: "#283350", fontWeight: "500" }}
                        >
                          {["ON_DATE", "BEFORE_DATE"].includes(
                            taskContent.taskDueDateType
                          )
                            ? `On ${moment(taskContent?.taskDueDate).format(
                                "ddd, MMM DD"
                              )}`
                            : "Flexible"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="mt--20">
                      <Typography sx={{ color: "#283350", fontWeight: "600" }}>
                        Details
                      </Typography>
                      <Typography
                        fontSize={14}
                        mt={1}
                        sx={{
                          wordBreak: "break-word",
                        }}
                      >
                        {taskContent.description}
                      </Typography>
                    </Box>
                    {!isEmpty(taskContent?.listingMedias) && (
                      <Box
                        className=" d-flex align-items-center flex-wrap"
                        sx={{
                          marginTop: "30px",
                          gap: "10px",
                          overflow: "hidden",
                          flexWrap: "wrap",
                        }}
                      >
                        {taskContent?.listingMedias.map((item, index) => (
                          <Avatar
                            key={index}
                            sx={{
                              width: 80,
                              height: 80,
                              borderRadius: "8px",
                              border: "1px solid black",
                              display: "flex",
                              cursor: "pointer",
                              justifyContent: "center",
                            }}
                            onClick={() => {
                              setMediaPreview({
                                index,
                                action: true,
                              });
                            }}
                            variant="square"
                            alt="Image"
                            src={item.mediaUrl}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                  {!isCompletedPost(taskContent) && !isAssignedPost(taskContent) && !isUserOwnPost(taskContent) && (
                    <>
                      <Box className="task-budget-wrapper ">
                        <Box className="active-task budget-section d-flex justify-content-between align-items-center flex-column">
                          {!taskContent?.budget ? (
                            <>
                              <Typography fontSize={12} textAlign="center">
                                {taskContent?.ownerUserProfile?.userDisplayName}{" "}
                                is keen to hear your offer
                              </Typography>
                              {isKeen ? (
                                <>
                                  {taskContent?.phoneNumber ? (
                                    <>
                                      <Box
                                        className="cursor-pointer mt-2"
                                        onClick={() => setShowPhone(true)}
                                      >
                                        <WhatsappIcon />
                                      </Box>
                                      {showPhone && (
                                        <Box className="d-flex flex-column align-items-center justify-content-start mt-2">
                                          <Chip
                                            label={taskContent?.phoneNumber}
                                            color={"success"}
                                            sx={{
                                              padding: "4px 20px",
                                            }}
                                          />
                                          <label>
                                            <MoreInfoInputIcon label={""} />{" "}
                                            {`Please contact ${taskContent?.ownerUserProfile?.userDisplayName} via whatsapp`}
                                          </label>
                                        </Box>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="post-btn mt-2"
                                        onClick={handleSamaylekhaChat}
                                      >
                                        100xHelper Chat
                                      </button>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {taskContent?.ownerUserProfile
                                    ?.userProfileId !== profileInfo?.id && (
                                    <Box
                                      className="d-flex align-items-center justify-content-between mt--10"
                                      gap={2}
                                    >
                                      <button
                                        title={
                                          profileInfo?.helperApplicationStatus ===
                                          "PENDING"
                                            ? "Your Helper status is pending."
                                            : ""
                                        }
                                        className={`post-btn ${
                                          profileInfo?.helperApplicationStatus ===
                                          "PENDING"
                                            ? "opacity--50 cursor-disabled "
                                            : ""
                                        }`}
                                        onClick={() => handleAuth()}
                                        disabled={
                                          profileInfo?.helperApplicationStatus ===
                                          "PENDING"
                                        }
                                      >
                                        Make an offer
                                      </button>
                                    </Box>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Typography fontSize={12} textAlign={"center"}>
                                Task Budget{" "}
                                {taskContent?.budgetType === "PER_HOUR" &&
                                  "Per Hour"}
                              </Typography>
                              <Typography
                                mt={1}
                                fontSize={44}
                                fontWeight={600}
                                color={"#283350"}
                              >
                                € {taskContent?.budget}
                              </Typography>

                              {taskContent.ownerUserProfile.userProfileId !==
                                profileInfo?.id && (
                                <Box
                                  className="d-flex flex-column align-items-center justify-content-between mt--10"
                                  gap={1}
                                >
                                  {taskContent?.preferredContactMethod ? (
                                    <>
                                      <Typography
                                        fontSize={"13px"}
                                        className="cursor-pointer"
                                        onClick={() => handleAuth()}
                                      >
                                        Make an offer
                                      </Typography>
                                      {taskContent?.phoneNumber ? (
                                        <>
                                          <Box
                                            className="cursor-pointer"
                                            onClick={() => setShowPhone(true)}
                                          >
                                            <WhatsappIcon />
                                          </Box>
                                          {showPhone && (
                                            <Box className="d-flex flex-column align-items-center justify-content-start">
                                              <Chip
                                                label={taskContent?.phoneNumber}
                                                color={"success"}
                                                sx={{
                                                  padding: "4px 20px",
                                                }}
                                              />
                                              <label>
                                                <MoreInfoInputIcon label={""} />{" "}
                                                {`Please contact ${taskContent?.ownerUserProfile?.userDisplayName} via whatsapp`}
                                              </label>
                                            </Box>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="post-btn"
                                            onClick={handleSamaylekhaChat}
                                          >
                                            100xHelper Chat
                                          </button>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {taskContent?.listingStatus !==
                                        "ASSIGNED" && (
                                        <button
                                          title={
                                            profileInfo?.helperApplicationStatus ===
                                            "PENDING"
                                              ? "Your Helper status is pending."
                                              : ""
                                          }
                                          className={`post-btn ${
                                            profileInfo?.helperApplicationStatus ===
                                            "PENDING"
                                              ? "opacity--50 cursor-disabled "
                                              : ""
                                          }`}
                                          onClick={() => handleAuth()}
                                          disabled={
                                            profileInfo?.helperApplicationStatus ===
                                            "PENDING"
                                          }
                                        >
                                          Make an offer
                                        </button>
                                      )}
                                    </>
                                  )}
                                </Box>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </Box>
          )}

          {window.innerWidth < 991.98 && !isEmpty(taskContent) && (
            <Box className="task-overlay">
              <Box
                onClick={() => setTaskContent({})}
                sx={{ padding: "5px 16px !important;" }}
              >
                <Chip label="&#8592; Back" />
              </Box>
              {!isEmpty(taskContent) && (
                <Box
                  className="p-2 d-flex"
                  gap={1}
                  sx={{ padding: "5px 16px !important;" }}
                >
                  <Box className="task-details">
                    <Box className="d-flex align-items-center" gap={1}>
                      {["OPEN", "ASSIGNED", "COMPLETED"].map((chip, index) => (
                        <Chip
                          key={index}
                          label={chip}
                          color={
                            taskContent.listingStatus === chip
                              ? "success"
                              : "default"
                          }
                        />
                      ))}
                    </Box>
                    <Typography
                      className="fw-bold"
                      mt={2}
                      fontSize={{
                        xs: "18px",
                        sm: "18px",
                        md: "20px",
                        color: "#283350",
                        fontWeight: "600",
                        fontSize: "28px",
                      }}
                    >
                      {taskContent.title}
                    </Typography>

                  {!isCompletedPost(taskContent) && !isAssignedPost(taskContent) && !isUserOwnPost(taskContent) && (
                    <>
                    <Box className="mt--20 task-budget-mobile">
                      <Box
                        className="active-task d-flex justify-content-between align-items-center flex-column"
                        sx={{
                          padding: "10px",
                          borderRadius: "8px",
                        }}
                      >
                        {!taskContent?.budget ? (
                          <>
                            <Typography fontSize={12} textAlign="center">
                              {" "}
                              {
                                taskContent?.ownerUserProfile?.userDisplayName
                              }{" "}
                              is keen to hear your offer
                            </Typography>
                            {isKeen ? (
                              <>
                                {taskContent?.phoneNumber ? (
                                  <>
                                    <Box
                                      className="cursor-pointer mt-2"
                                      onClick={() => setShowPhone(true)}
                                    >
                                      <WhatsappIcon />
                                    </Box>
                                    {showPhone && (
                                      <Box className="d-flex flex-column align-items-center justify-content-start mt-2">
                                        <Chip
                                          label={taskContent?.phoneNumber}
                                          color={"success"}
                                          sx={{
                                            padding: "4px 20px",
                                          }}
                                        />
                                        <label>
                                          <MoreInfoInputIcon label={""} />{" "}
                                          {`Please contact ${taskContent?.ownerUserProfile?.userDisplayName} via whatsapp`}
                                        </label>
                                      </Box>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="post-btn mt-2"
                                      onClick={handleSamaylekhaChat}
                                    >
                                      100xHelper Chat
                                    </button>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {taskContent?.ownerUserProfile
                                  ?.userProfileId !== profileInfo?.id && (
                                  <Box
                                    className="d-flex align-items-center justify-content-between mt--10"
                                    gap={2}
                                  >
                                    <button
                                      title={
                                        profileInfo?.helperApplicationStatus ===
                                        "PENDING"
                                          ? "Your Helper status is pending."
                                          : ""
                                      }
                                      className={`post-btn ${
                                        profileInfo?.helperApplicationStatus ===
                                        "PENDING"
                                          ? "opacity--50 cursor-disabled "
                                          : ""
                                      }`}
                                      onClick={() => handleAuth()}
                                      disabled={
                                        profileInfo?.helperApplicationStatus ===
                                        "PENDING"
                                      }
                                    >
                                      Make an offer
                                    </button>
                                  </Box>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Typography fontSize={12} textAlign={"center"}>
                              Task Budget{" "}
                              {taskContent?.budgetType === "PER_HOUR" &&
                                "Per Hour"}
                            </Typography>
                            <Typography
                              mt={1}
                              fontSize={44}
                              fontWeight={600}
                              color={"#283350"}
                            >
                              {" "}
                              € {taskContent?.budget}
                            </Typography>

                            {taskContent?.ownerUserProfile.userProfileId !==
                              profileInfo?.id && (
                              <Box
                                className="d-flex flex-column align-items-center justify-content-between mt--10"
                                gap={1}
                              >
                                {taskContent?.preferredContactMethod ? (
                                  <>
                                    <Typography
                                      fontSize={"14px"}
                                      className="cursor-pointer"
                                      onClick={() => handleAuth()}
                                    >
                                      Make an offer
                                    </Typography>
                                    {taskContent?.phoneNumber ? (
                                      <>
                                        <Box
                                          className="cursor-pointer"
                                          onClick={() => setShowPhone(true)}
                                        >
                                          <WhatsappIcon />
                                        </Box>
                                        {showPhone && (
                                          <Box className="d-flex flex-column align-items-center justify-content-start">
                                            <Chip
                                              label={taskContent?.phoneNumber}
                                              color={"success"}
                                              sx={{
                                                padding: "4px 20px",
                                              }}
                                            />
                                            <label>
                                              <MoreInfoInputIcon label={""} />{" "}
                                              {`Please contact ${taskContent?.ownerUserProfile?.userDisplayName} via whatsapp`}
                                            </label>
                                          </Box>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        <button
                                          className="post-btn"
                                          onClick={handleSamaylekhaChat}
                                        >
                                          100xHelper Chat
                                        </button>
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {taskContent?.listingStatus !==
                                      "ASSIGNED" && (
                                      <button
                                        title={
                                          profileInfo?.helperApplicationStatus ===
                                          "PENDING"
                                            ? "Your Helper status is pending."
                                            : ""
                                        }
                                        className={`post-btn ${
                                          profileInfo?.helperApplicationStatus ===
                                          "PENDING"
                                            ? "opacity--50 cursor-disabled "
                                            : ""
                                        }`}
                                        onClick={() => handleAuth()}
                                        disabled={
                                          profileInfo?.helperApplicationStatus ===
                                          "PENDING"
                                        }
                                      >
                                        Make an offer
                                      </button>
                                    )}
                                  </>
                                )}
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  </>
                  )}
                  
                    <Box className="d-flex justify-content-between align-items-center mt--20">
                      <Box className="d-flex align-items-center" gap={1}>
                        <Box>
                          <Avatar
                            sx={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                            variant="square"
                            alt={taskContent?.ownerUserProfile?.userDisplayName}
                            src={
                              taskContent?.ownerUserProfile?.userProfileImageUrl
                            }
                          />
                        </Box>
                        <Box>
                          <Typography fontSize={12} fontWeight={"medium"}>
                            POSTED BY
                          </Typography>
                          <Typography
                            fontSize={12}
                            sx={{ color: "#283350", fontWeight: "500" }}
                          >
                            {taskContent?.ownerUserProfile?.userDisplayName}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography fontSize={12}>
                          {" "}
                          {getTimeString(taskContent.createTime)}
                        </Typography>
                      </Box>
                    </Box>

                    {taskContent.listingLocation?.locationPlaceId && (
                      <>
                        <Box className="mt--20 d-flex" gap={1}>
                          <Box
                            sx={{ minWidth: "40px" }}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <LocationSVGIcon size={"30"} color={"#283350"} />
                          </Box>
                          <Box>
                            <Typography fontSize={12} fontWeight={"medium"}>
                              LOCATION
                            </Typography>
                            <Typography
                              fontSize={12}
                              sx={{ color: "#283350", fontWeight: "500" }}
                            >
                              {taskContent.locationType === "IN_PERSON"
                                ? `${taskContent?.listingLocation?.locationPlaceId}`
                                : "Remote"}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    )}
                    <Box className="mt--20 d-flex" gap={1}>
                      <Box
                        sx={{ minWidth: "40px" }}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <CalenderIcon size={"30"} color={"#283350"} />
                      </Box>
                      <Box>
                        <Typography fontSize={12} fontWeight={"medium"}>
                          TO BE DONE ON
                        </Typography>
                        <Typography
                          fontSize={12}
                          sx={{ color: "#283350", fontWeight: "500" }}
                        >
                          {["ON_DATE", "BEFORE_DATE"].includes(
                            taskContent.taskDueDateType
                          )
                            ? `On ${moment(taskContent?.taskDueDate).format(
                                "ddd, MMM DD"
                              )}`
                            : "Flexible"}
                        </Typography>
                      </Box>
                    </Box>
                    <Box className="mt--20">
                      <Typography sx={{ color: "#283350", fontWeight: "600" }}>
                        Details
                      </Typography>
                      <Typography
                        fontSize={14}
                        mt={1}
                        sx={{
                          wordBreak: "break-word",
                        }}
                      >
                        {taskContent.description}
                      </Typography>
                    </Box>
                    {!isEmpty(taskContent?.listingMedias) && (
                      <Box
                        className=" d-flex align-items-center flex-wrap"
                        sx={{
                          marginTop: "30px",
                          gap: "10px",
                          overflow: "hidden",
                          flexWrap: "wrap",
                        }}
                      >
                        {taskContent?.listingMedias.map((item, index) => (
                          <Avatar
                            key={index}
                            sx={{
                              width: 80,
                              height: 80,
                              borderRadius: "8px",
                              border: "1px solid black",
                              display: "flex",
                              cursor: "pointer",
                              justifyContent: "center",
                            }}
                            onClick={() =>
                              setMediaPreview({
                                index,
                                action: true,
                              })
                            }
                            variant="square"
                            alt="Image"
                            src={item.mediaUrl}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                  {!isCompletedPost(taskContent) && !isAssignedPost(taskContent) && !isUserOwnPost(taskContent) && (
                    <>
                      <Box className="task-budget-wrapper">
                        <Box className="active-task budget-section d-flex justify-content-between align-items-center flex-column">
                          {!taskContent?.budget ? (
                            <>
                              <Typography fontSize={12} textAlign="center">
                                {" "}
                                {
                                  taskContent?.ownerUserProfile?.userDisplayName
                                }{" "}
                                is keen to hear your offer
                              </Typography>
                              {isKeen ? (
                                <>
                                  {taskContent?.phoneNumber ? (
                                    <>
                                      <Box
                                        className="cursor-pointer mt-2"
                                        onClick={() => setShowPhone(true)}
                                      >
                                        <WhatsappIcon />
                                      </Box>
                                      {showPhone && (
                                        <Box className="d-flex flex-column align-items-center justify-content-start mt-2">
                                          <Chip
                                            label={taskContent?.phoneNumber}
                                            color={"success"}
                                            sx={{
                                              padding: "4px 20px",
                                            }}
                                          />
                                          <label>
                                            <MoreInfoInputIcon label={""} />{" "}
                                            {`Please contact ${taskContent?.ownerUserProfile?.userDisplayName} via whatsapp`}
                                          </label>
                                        </Box>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="post-btn mt-2"
                                        onClick={handleSamaylekhaChat}
                                      >
                                        100xHelper Chat
                                      </button>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {taskContent?.ownerUserProfile
                                    ?.userProfileId !== profileInfo?.id && (
                                    <Box
                                      className="d-flex align-items-center justify-content-between mt--10"
                                      gap={2}
                                    >
                                      <button
                                        title={
                                          profileInfo?.helperApplicationStatus ===
                                          "PENDING"
                                            ? "Your Helper status is pending."
                                            : ""
                                        }
                                        className={`post-btn ${
                                          profileInfo?.helperApplicationStatus ===
                                          "PENDING"
                                            ? "opacity--50 cursor-disabled "
                                            : ""
                                        }`}
                                        onClick={() => handleAuth()}
                                        disabled={
                                          profileInfo?.helperApplicationStatus ===
                                          "PENDING"
                                        }
                                      >
                                        Make an offer
                                      </button>
                                    </Box>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <Typography fontSize={12} textAlign={"center"}>
                                Task Budget{" "}
                                {taskContent?.budgetType === "PER_HOUR" &&
                                  "Per Hour"}
                              </Typography>
                              <Typography
                                mt={1}
                                fontSize={44}
                                fontWeight={600}
                                color={"#283350"}
                              >
                                € {taskContent?.budget}
                              </Typography>

                              {taskContent?.ownerUserProfile?.userProfileId !==
                                profileInfo?.id && (
                                <Box
                                  className="d-flex flex-column align-items-center justify-content-between mt--10"
                                  gap={1}
                                >
                                  {taskContent?.preferredContactMethod ? (
                                    <>
                                      <Typography
                                        fontSize={"14px"}
                                        className="cursor-pointer"
                                        onClick={() => handleAuth()}
                                      >
                                        Make an offer
                                      </Typography>

                                      {taskContent?.phoneNumber ? (
                                        <>
                                          <Box
                                            className="cursor-pointer"
                                            onClick={() => setShowPhone(true)}
                                          >
                                            <WhatsappIcon />
                                          </Box>
                                          {showPhone && (
                                            <Box className="d-flex flex-column align-items-center justify-content-start">
                                              <Chip
                                                label={taskContent?.phoneNumber}
                                                color={"success"}
                                                sx={{
                                                  padding: "4px 20px",
                                                }}
                                              />
                                              <label>
                                                <MoreInfoInputIcon label={""} />{" "}
                                                {`Please contact ${taskContent?.ownerUserProfile?.userDisplayName} via whatsapp`}
                                              </label>
                                            </Box>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="post-btn"
                                            onClick={handleSamaylekhaChat}
                                          >
                                            100xHelper Chat
                                          </button>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {taskContent?.listingStatus !==
                                        "ASSIGNED" && (
                                        <button
                                          title={
                                            profileInfo?.helperApplicationStatus ===
                                            "PENDING"
                                              ? "Your Helper status is pending."
                                              : ""
                                          }
                                          className={`post-btn ${
                                            profileInfo?.helperApplicationStatus ===
                                            "PENDING"
                                              ? "opacity--50 cursor-disabled "
                                              : ""
                                          }`}
                                          onClick={() => handleAuth()}
                                          disabled={
                                            profileInfo?.helperApplicationStatus ===
                                            "PENDING"
                                          }
                                        >
                                          Make an offer
                                        </button>
                                      )}
                                    </>
                                  )}
                                </Box>
                              )}
                            </>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}

      {openLoginPopUp && (
        <LoginPopUp
          open={openLoginPopUp}
          handleClose={() => setOpenLoginPopUp(false)}
        />
      )}
      {isBecomeHelperNotify && (
        <BecomeHelperNotifyPopup
          onSubmit={() => {
            setIsBecomeHelperNotify(false);
            setIsBecomeHelperForm(true);
          }}
          handleClose={() => setIsBecomeHelperNotify(false)}
          isOpen={isBecomeHelperNotify}
        />
      )}
      {isBecomeHelperForm && (
        <BecomeTasker
          handleClose={() => setIsBecomeHelperForm(false)}
          isOpen={isBecomeHelperForm}
        />
      )}
      {mediaPreview.action && (
        <TaskImagePreview
          open={mediaPreview.action}
          handleClose={() =>
            setMediaPreview({
              action: false,
              index: null,
            })
          }
          startPosition={mediaPreview.index}
          taskContent={taskContent}
        />
      )}
    </Box>
  );
};

export default TaskListing;
