import moment from "moment";
import {
  AfterNoonIcon,
  EveningIcon,
  LocationIcon,
  MobileIcon,
  MorningIcon,
} from "./icons";

export const CONTACT_US_MENU = [
  {
    key: "Blogs",
    route: "/blogs",
  },
  {
    key: "About Us",
    route: "/about-us",
  },
  {
    key: "Contact Us",
    route: "/contact-us",
  },
  // {
  //   key: "Imprint",
  //   route: "/imprint",
  // },
  // {
  //   key: "Privacy Policy",
  //   route: "/privacy-policy",
  // },
  // {
  //   key: "Terms & conditions",
  //   route: "/terms",
  // },
  // {
  //   key: "Copyright statement",
  //   route: "/copyright",
  // },
];

export const getDayName = (day) => {
  switch (day) {
    case "sunday":
      return "Sunday";
    case "monday":
      return "Monday";
    case "tuesday":
      return "Tuesday";
    case "wednesday":
      return "Wednesday";
    case "thursday":
      return "Thursday";
    case "friday":
      return "Friday";
    case "saturday":
      return "Saturday";
    default:
      return "";
  }
};

export const STEPS = [
  "Task Details",
  "Location & Description",
  "Budget & Contact",
];
export const SKILLS_MENU = [
  {
    key: "Cleaning",
    value: "Cleaning",
  },
  {
    key: "Cooking",
    value: "Cooking",
  },
  {
    key: "Ironing",
    value: "Ironing",
  },
  {
    key: "Translation",
    value: "Translation",
  },
  {
    key: "Photography",
    value: "Photography",
  },
];

export const LANGUAGES_MENU = [
  { key: "Arabic", value: "ARABIC" },
  { key: "Bengali", value: "BENGALI" },
  { key: "Bulgarian", value: "BULGARIAN" },
  { key: "Croatian", value: "CROATIAN" },
  { key: "Czech", value: "CZECH" },
  { key: "Danish", value: "DANISH" },
  { key: "Dutch", value: "DUTCH" },
  { key: "English", value: "ENGLISH" },
  { key: "Estonian", value: "ESTONIAN" },
  { key: "Finnish", value: "FINNISH" },
  { key: "French", value: "FRENCH" },
  { key: "German", value: "GERMAN" },
  { key: "Greek", value: "GREEK" },
  { key: "Gujarati", value: "GUJARATI" },
  { key: "Hausa", value: "HAUSA" },
  { key: "Hindi", value: "HINDI" },
  { key: "Hungarian", value: "HUNGARIAN" },
  { key: "Italian", value: "ITALIAN" },
  { key: "Japanese", value: "JAPANESE" },
  { key: "Kannada", value: "KANNADA" },
  { key: "Korean", value: "KOREAN" },
  { key: "Lahanda", value: "LAHANDA" },
  { key: "Latvian", value: "LATVIAN" },
  { key: "Lithuanian", value: "LITHUANIAN" },
  { key: "Malay", value: "MALAY" },
  { key: "Malayalam", value: "MALAYALAM" },
  { key: "Mandarin", value: "MANDARIN" },
  { key: "Marathi", value: "MARATHI" },
  { key: "Norwegian", value: "NORWEGIAN" },
  { key: "Oriya", value: "ORIYA" },
  { key: "Persian", value: "PERSIAN" },
  { key: "Polish", value: "POLISH" },
  { key: "Portuguese", value: "PORTUGUESE" },
  { key: "Punjabi", value: "PUNJABI" },
  { key: "Romanian", value: "ROMANIAN" },
  { key: "Russian", value: "RUSSIAN" },
  { key: "Serbian", value: "SERBIAN" },
  { key: "Slovak", value: "SLOVAK" },
  { key: "Slovenian", value: "SLOVENIAN" },
  { key: "Spanish", value: "SPANISH" },
  { key: "Swahili", value: "SWAHILI" },
  { key: "Swedish", value: "SWEDISH" },
  { key: "Tamil", value: "TAMIL" },
  { key: "Telugu", value: "TELUGU" },
  { key: "Thai", value: "THAI" },
  { key: "Urdu", value: "URDU" },
  { key: "Vietnamese", value: "VIETNAMESE" },
];

export const TASK_DUE_DATE = [
  {
    key: "As soon as Possible",
    value: "AS_SOON_AS_POSSIBLE",
  },
  {
    key: "On date",
    value: "ON_DATE",
  },
  {
    key: "Before Date",
    value: "BEFORE_DATE",
  },
  {
    key: "Flexible",
    value: "FLEXIBLE",
  },
];

export const FLEXIBLE_DATE = [
  {
    label: "Morning",
    value: "MORNING",
    subText: "Before 12:00",
    icon: <MorningIcon />,
  },
  {
    label: "Afternoon",
    value: "AFTERNOON",
    subText: "12:00 to 18:00",
    icon: <AfterNoonIcon />,
  },
  {
    label: "Evening",
    value: "EVENING",
    subText: "After 18:00",
    icon: <EveningIcon />,
  },
];
export const PLACE_OPTIONS = [
  {
    label: "In-Person",
    value: "IN_PERSON",
    icon: <LocationIcon />,
  },
  {
    label: "Remote",
    value: "REMOTE",
    icon: <MobileIcon />,
  },
];

export const BUDGET_OPTIONS = [
  {
    key: "Total budget",
    value: "TOTAL_BUDGET",
    title: "",
  },
  {
    key: "Per hour rate",
    value: "PER_HOUR",
    title: "",
  },
  {
    key: "Open for offers",
    value: "OPEN_FOR_OFFERS",
    title: "Helper can send you price instead.",
  },
];

export const PREFERRED_CONTACTS = [
  {
    key: "100xHelper Chat",
    value: "SAMAYLEKHA_CHAT",
  },
  {
    key: "WhatsApp",
    value: "WHATS_APP",
  },
];

export const STEPPER_INITIAL_VALUES = {
  isFlexible: false,
  categoryId: "",
  termsAccepted: true,
  listingStatus: "OPEN",
  title: "",
  taskDueDateType: "",
  taskDueDate: "",
  preferredTimeOfDay: [],
  description: "",
  locationType: "IN_PERSON",
  listingLocation: {
    city: {
      cityName: "",
      placeId: "",
      country: {
        countryName: "",
      },
    },
    latitude: 0,
    longitude: 0,
    locationPlaceId: "",
  },
  budgetType: "TOTAL_BUDGET",
  phoneNumber: "",
  listingMedias: [],
  budget: "",
  preferredContactMethod: "SAMAYLEKHA_CHAT",
};

export const PlaceIdArray = [
  {
    key: "Berlin",
    value: "ChIJAVkDPzdOqEcRcDteW0YgIQQ",
  },
  { key: "Hamburg", value: "ChIJuRMYfoNhsUcRoDrWe_I9JgQ" },
  {
    key: "Munich (München)",
    value: "ChIJ2V-Mo_l1nkcRfZixfUq4DAE",
  },
  {
    key: "Cologne (Köln)",
    value: "ChIJ5S-raZElv0cR8HcqSvxgJwQ",
  },
  {
    key: "Frankfurt",
    value: "ChIJxZZwR28JvUcRAMawKVBDIgQ",
  },
  {
    key: "Stuttgart",
    value: "ChIJ04-twTTbmUcR5M-RdxzB1Xk",
  },
  {
    key: "Düsseldorf",
    value: "ChIJB1lG8XvJuEcRsHMqSvxgJwQ",
  },
  {
    key: "Dortmund",
    value: "ChIJEXrwv2AXuUcRUIdUMYHyJwQ",
  },
  {
    key: "Essen",
    value: "ChIJOfarlrfCuEcRnSytpBHhAGo",
  },
  {
    key: "Leipzig",
    value: "ChIJcywPIBj4pkcRUvW0udKA35M",
  },
  {
    key: "Hannover",
    value: "ChIJhU9JTVELsEcRIEeslG2sJQQ",
  },
  {
    key: "Nuremberg (Nürnberg)",
    value: "ChIJ0xy2ta5Xn0cRtyGMqYWvXd0",
  },
  {
    key: "Bremen",
    value: "ChIJNePuDBAosUcRUd83-VyI6MI",
  },
  {
    key: "Dresden",
    value: "ChIJqdYaECnPCUcRsP6IQsuxIQQ",
  },
  {
    key: "Bonn",
    value: "ChIJSdrLfJ_hvkcRF5ZcaMb424Y",
  },
  {
    key: "Mannheim",
    value: "ChIJRT-OUSTMl0cRh-YGpHr-5LE",
  },
  {
    key: "Duisburg",
    value: "ChIJOyeLpfW-uEcRwHNUMYHyJwQ",
  },
  {
    key: "Bochum",
    value: "ChIJdVfT-Q_guEcRQIdUMYHyJwQ",
  },
  {
    key: "Wuppertal",
    value: "ChIJZ9RhXDrWuEcRQHQqSvxgJwQ",
  },
  {
    key: "Heidelberg",
    value: "ChIJzdzMDgXBl0cR1zokRADq5u8",
  },
  {
    key: "Freiburg",
    value: "ChIJwx2wtGrns0cRMFjWe_I9JgQ",
  },
  {
    key: "Aachen",
    value: "ChIJHRmKsHyZwEcRT0QOC64Oo2M",
  },
  {
    key: "Göttingen",
    value: "ChIJx8qYb7jUpEcRMD6slG2sJQQ",
  },
  {
    key: "Freiburg im Breisgau",
    value: "ChIJZdYLViYbkUcRsFffpbdrHwQ",
  },
];
export const getTimeWiseGreeting = () => {
  const currentHour = moment().hour();

  if (currentHour >= 0 && currentHour < 12) {
    return "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    return "Good Afternoon";
  } else {
    return "Good Evening";
  }
};

export const getTimeString = (time) => {
  let momentObj = moment.unix(time);
  return momentObj.fromNow();
};

export const How_It_Works = [
  {
    id: "Step-1",
    title: "Post Your Task",
    icon: "post-task",
    description:
      "Provide task details like title, description, due date, and budget (or select 'Open For Offers').",
  },
  {
    id: "Step-2",
    title: "Connect with Helpers",
    icon: "connect-helper",
    description:
      "Verified helpers will contact you through your preferred method to discuss details.",
  },
  {
    id: "Step-3",
    title: "Discuss Payment Terms",
    icon: "payment-terms",
    description:
      "Agree on the payment amount and method directly with the helper.",
  },
  {
    id: "Step-4",
    title: "Complete the Task",
    icon: "complete-task",
    description:
      "Pay the helper as agreed and mark the task as complete in 'My Tasks'.",
  },
];

export const FAQAccordion = [
  {
    question: "How do I post a task?",
    answer:
      "Posting a task is simple and free. Just provide details such as the title, description, due date, budget (or select 'Open For Offers'), and your preferred contact method (WhatsApp or 100xHelper chat).",
  },
  {
    question: "How do I choose the right helper?",
    answer:
      "Verified helpers will contact you after you post a task. Review their profiles, discuss the task details, and choose the helper that best meets your criteria.",
  },
  {
    question: "How do I negotiate payment terms?",
    answer:
      "Discuss and agree on the payment amount and method directly with the helper. Make sure all terms are clear and documented.",
  },
  {
    question: "What if I have a dispute with a helper?",
    answer:
      "Try to resolve any issues through clear communication. If you need further assistance, contact our support team.",
  },
  {
    question: "How do I mark a task as complete?",
    answer:
      "Once the task is finished and you are satisfied with the work, go to the 'My Tasks' section on our website and mark the task as complete.",
  },
  {
    question: "Is my personal information safe?",
    answer:
      "Yes, we prioritize your privacy and security. Use our secure communication methods and avoid sharing unnecessary personal information. In the future, if I need the same help, do I need to post a new task or can I reactivate the old one?",
  },
  {
    question:
      "In the future, if I need the same help, do I need to post a new task or can I reactivate the old one?",
    answer:
      "In the 'My Tasks' section, you will be able to repost a task that is already completed. Reposting helps you save time by copying the existing task details to create a new one.",
  },
];

export const BlogsSidebarList = [
  {
    id: "topBlog-1",
    title: "Save Time with 100xHelper's Errand Services",
    imagePath: "/assets/img/blogs-images/blog-2.jpg",
    blogOwnerName: "Rushin Doshi",
    blogOwnerImage: "/assets/img/blogs-images/avatar3.png",
    blogDate: "15 August 2024",
    description:
      "In today's fast-paced world, time is a commodity many of us wish we had more of. Between work, family commitments, and personal care, it can be challenging to find time for everyday tasks and errands. This is where 100xHelper steps in, offering a streamlined way to manage your to-do list with reliable errand services. Whether you need groceries delivered, packages picked up, or your dry cleaning taken care of, 100xHelper connects you with trusted local helpers ready to assist.",
    blogContent: `
      <h1>Why Errand Services Are a Game Changer</h1>
      <p>
        <strong>1. Increased Productivity:</strong>  Delegating tasks like shopping, mailing, or picking up items can free up significant amounts of your time, allowing you to focus on what's truly important—be it your career, education, or quality time with loved ones..<br><br>
        <strong>2. Stress Reduction:</strong> Managing a full list of errands can be stressful, especially when balanced with other responsibilities. Using errand services through 100xHelper can help reduce this stress by handling these tasks for you.<br><br>
        <strong>3. Flexibility:</strong> With 100xHelper, you can arrange errand services around your schedule, ensuring that your tasks are completed when it suits you best. This flexibility is invaluable for those with unpredictable or busy schedules.<br><br>
      </p>
      <h2>How Errand Services on 100xHelper Work</h2>
      <p><strong>Simple and Transparent Process:</strong></p>
      <p>
        <strong>1. Post Your Task:</strong> Detail what errand you need help with, whether it's grocery shopping, parcel delivery, or something else. Specify any preferences or instructions that might help the helper meet your needs.<br><br>
        <strong>2. Receive Responses from Helpers:</strong> Helpers interested in completing your task will respond with their offers, allowing you to choose based on their rates and previous user ratings.<br><br>
        <strong>3. Select Your Helper:</strong> Choose the helper who best fits your needs based on their proposal and profile. This choice can be influenced by their proximity to the errand location, their availability, or their experience with similar tasks.<br>
      </p>
      <p>
        <strong>Secure and Efficient: </strong>Each step is conducted through the 100xHelper platform, ensuring that all transactions are secure and communication is clear. This process is designed to be efficient from posting the task to task completion.<br><br>
      </p>
      <h1>The Impact of Outsourcing Your Errands</h1>
      <p>Imagine a day where instead of running around the city, you're taking a well-deserved break or focusing on a project that matters to you. Outsourcing your errands doesn't just save time; it enhances your quality of life. It turns a potentially hectic day into one that's productive and calm. Clients who use 100xHelper for errand services often find they are more present in the moments that matter, leading to a more fulfilling daily life.</p>
      <h1>Conclusion</h1>
      <p>
        With 100xHelper, managing your daily tasks doesn’t have to be a burden. Our platform offers a convenient, flexible, and reliable way to handle your errands, giving you back the time you need to focus on what’s most important.
      </p>
      <p>
        <strong>Ready to simplify your life? Post your task on 100xHelper today and discover how easy it is to tick off your to-do list with the help of trusted helpers on our platform!
        </strong>
      </p>`,
  },
  {
    id: "topBlog-2",
    title: "Translation Services: Overcome Language Barriers",
    imagePath: "/assets/img/blogs-images/blog-4.jpg",
    blogOwnerName: "Tushar Dudhatra",
    blogOwnerImage: "/assets/img/blogs-images/avatar3.png",
    blogDate: "15 August 2024",
    description:
      "Navigating a new city and culture can be thrilling, but also challenging—especially when there's a language barrier. In Berlin, where German is the lingua franca, understanding and communicating effectively can be crucial for both personal and professional success. Here’s why and when you might need the specialized skills of a German translator, rather than relying on automated tools like Google Translator.",
    blogContent:`
      <h1>Finding the Right German Translator in Berlin</h1><br>
      <h4>Why Use a Professional German Translator?</h4>
      <p>
        <strong>Accuracy and Nuance:</strong> Automated translation tools are improving, but they often miss the nuances of language that can be critical in formal or complex interactions. A professional translator ensures that the subtleties and cultural nuances are correctly interpreted, which can be crucial in legal, medical, or business settings.<br><br>
        <strong>Confidentiality and Trust:</strong> For sensitive matters, professional translators provide the confidentiality that online tools cannot guarantee. This is particularly important for legal documents, medical records, or any personal information that requires discretion.<br><br>
        <strong>Localized Knowledge:</strong> Professional translators bring local knowledge that automated tools lack. This can include understanding local idioms, cultural references, or the bureaucratic specifics of the German administration, which can be indispensable for navigating local systems effectively.<br><br>
      </p>
      <h4>Essential Areas for Professional German Translation Services</h4>
      <p>
        <strong>Legal Proceedings:</strong> Understanding legal documents, contracts, or proceedings in court requires precise language. Misinterpretations can lead to serious consequences, making professional translation essential.<br><br>
        <strong>Medical Consultations: </strong> In healthcare, accurate communication can be a matter of safety. Professional translators help ensure that medical advice and patient concerns are clearly understood by all parties.<br><br>
        <strong>Business Negotiations:</strong> For those establishing or running a business, negotiations and contractual discussions benefit significantly from precise translation. This ensures that all parties are unequivocally clear about terms and conditions.<br><br>
        <strong>Academic and Research Work:</strong> When studying or participating in research in Berlin, professional translation can help in understanding complex academic materials or in communicating your research to a German-speaking audience.<br><br>
        <strong>Real Estate Transactions:</strong> Buying or renting property involves intricate documentation and negotiation, areas where professional translators can provide invaluable assistance to ensure that agreements are clear and legally binding.<br><br>
        <strong>Government and Immigration Documentation:</strong> Navigating government paperwork can be daunting. Professional translators can help with applications, interviews, and all forms of bureaucratic communication, ensuring that submissions are correctly completed and understood.<br><br>
      </p>
      <h2>Conclusion</h2>
      <p>Having access to a professional German translator can be your key to unlocking a smoother, more effective interaction in multiple facets of life. Whether it’s for navigating the complexities of a new city, settling into a new job, or handling legal matters, 100xHelper connects you with trusted German translators who can bridge the gap between languages and cultures.</p>
      <p><strong>Ready to ensure your communications are clear and professional?</strong> Find your German translator on 100xHelper now and experience the peace of mind that comes with knowing you're fully understood.</p>`
  },
  // {
  //   id: "topBlog-1",
  //   title: "Post-Event Survey Questions",
  //   imagePath: "/assets/img/blogs-images/image-1.jpeg",
  //   blogOwnerName: "John Doe",
  //   blogOwnerImage: "/assets/img/blogs-images/avatar3.jpg",
  //   blogDate: "29th June 2024",
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
  //   blogContent: `<p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</b></p>
  //     <h1>Welcome to the Site</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
  //     <h1>Nail the timing</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>`,
  // },
  // {
  //   id: "topBlog-2",
  //   title: "Welcome to 100xHelper",
  //   imagePath: "/assets/img/blogs-images/image-2.jpg",
  //   blogOwnerName: "John Doe",
  //   blogOwnerImage: "/assets/img/blogs-images/avatar3.jpg",
  //   blogDate: "29th June 2024",
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
  //   blogContent: `<p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</b></p>
  //     <h1>Welcome to the Site</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
  //     <h1>Nail the timing</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>`,
  // },
  // {
  //   id: "topBlog-3",
  //   title: "How to put on your own festival",
  //   imagePath: "/assets/img/blogs-images/image-3.jpg",
  //   blogOwnerName: "John Doe",
  //   blogOwnerImage: "/assets/img/blogs-images/avatar3.jpg",
  //   blogDate: "29th June 2024",
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
  //   blogContent: `<p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</b></p>
  //     <h1>Welcome to the Site</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
  //     <h1>Nail the timing</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>`,
  // },
  // {
  //   id: "topBlog-4",
  //   title: "Everything you need to know about Remittance",
  //   imagePath: "/assets/img/blogs-images/image-1.jpeg",
  //   blogOwnerName: "John Doe",
  //   blogOwnerImage: "/assets/img/blogs-images/avatar3.jpg",
  //   blogDate: "29th June 2024",
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
  //   blogContent: `<p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</b></p>
  //     <h1>Welcome to the Site</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
  //     <h1>Nail the timing</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>`,
  // },
  // {
  //   id: "topBlog-5",
  //   title: "How to sell tickets on your website",
  //   imagePath: "/assets/img/blogs-images/image-2.jpg",
  //   blogOwnerName: "John Doe",
  //   blogOwnerImage: "/assets/img/blogs-images/avatar3.jpg",
  //   blogDate: "29th June 2024",
  //   description:
  //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
  //   blogContent: `<p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</b></p>
  //     <h1>Welcome to the Site</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>
  //     <h1>Nail the timing</h1>
  //     <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries</p>`,
  // },
];

export const BlogContentList = [
  {
    id: "blog-1",
    title: "Reliable Cleaning Help in Berlin via 100xHelper",
    imagePath: "/assets/img/blogs-images/blog-1.jpg",
    blogOwnerName: "Tushar Dudhatara",
    blogOwnerImage: "/assets/img/blogs-images/avatar3.png",
    blogDate: "15 August 2024",
    description:
      "Finding trustworthy and efficient cleaning help in a bustling city like Berlin can be challenging, especially if you're new to the area. With 100xHelper, we've made it easier for you to connect with trusted cleaning professionals who can assist with everything from routine cleaning to special deep-cleaning projects.",
    blogContent: `
      <h1>Why Choose 100xHelper for Cleaning Services?</h1>
      <p>At 100xHelper, we know the importance of maintaining a clean and organized home. Our platform connects you with trusted helpers who provide cleaning services, making it easy to find the right assistance without the hassle of searching endlessly.</p>
      <p>
        <strong>1. Simple and Convenient Process:</strong>  To get started, just post your cleaning needs on 100xHelper. Provide details about the scope of work, your preferred schedule, and any specific preferences. This helps potential helpers understand your requirements and reach out directly.<br><br>
        <strong>2. Handpicked Helpers:</strong> Safety and reliability are our top priorities. All helpers on 100xHelper undergo a verification process, including ID checks and interviews over a video call. This ensures that the person coming to your home is trustworthy.<br><br>
        <strong>3. Tailored to Your Needs:</strong> Whether you need a one-time deep clean, regular housekeeping, or specific tasks like window cleaning or carpet shampooing, 100xHelper offers flexibility. You can discuss and negotiate the details directly with the helper to ensure they meet your expectations.<br><br>
        <strong>4. Transparent Communication:</strong>With 100xHelper, you can communicate directly with helpers, ask questions, and clarify any doubts before hiring. This transparency ensures that both parties are on the same page, leading to a smoother experience.<br><br>
      </p>
      <h2>Tips for Finding the Right Cleaning Helper</h2>
      <p>
        <strong>1. Be Specific in Your Request:</strong> Clearly state what areas need cleaning and if a helper needs to bring any equipment or cleaning materials with them. This helps attract helpers who are best suited for your needs.<br><br>
        <strong>2. Ask for References:</strong>Don’t hesitate to ask for references or reviews from previous clients. This can give you insight into the helper’s reliability and quality of work.<br><br>
        <strong>3. Set Clear Expectations:</strong> Discuss your expectations regarding punctuality, cleaning standards, and payment terms upfront. This helps avoid misunderstandings later.<br><br>
        <strong>4. Prepare Your Home:</strong> Before the helper arrives, make sure the areas to be cleaned are accessible and free from clutter. This allows them to work efficiently and focus on the tasks at hand.<br><br>
        <strong>5. Set Clear Expectations:</strong> After the service, provide feedback to the helper and on the platform. This not only helps improve their service but also assists other users in making informed decisions.<br><br>
      </p>
      <h1>Join the 100xHelper Community Today</h1>
      <p>Whether you’re looking for a one-time clean before guests arrive or regular housekeeping, 100xHelper makes it easy to find the right cleaning help in Berlin. Our community of trusted helpers is ready to assist, ensuring you can enjoy a clean and comfortable home without the stress.</p>
      <p><strong>Ready to find your perfect cleaning helper? </strong></strong>Visit 100xHelper.de today and simplify your life!</p>`,
  },
  // {
  //   id: "blog-2",
  //   title: "Save Time with 100xHelper's Errand Services",
  //   imagePath: "/assets/img/blogs-images/blog-2.jpg",
  //   blogOwnerName: "Rushin Doshi",
  //   blogOwnerImage: "/assets/img/blogs-images/avatar3.png",
  //   blogDate: "15 August 2024",
  //   description:
  //     "In today's fast-paced world, time is a commodity many of us wish we had more of. Between work, family commitments, and personal care, it can be challenging to find time for everyday tasks and errands. This is where 100xHelper steps in, offering a streamlined way to manage your to-do list with reliable errand services. Whether you need groceries delivered, packages picked up, or your dry cleaning taken care of, 100xHelper connects you with trusted local helpers ready to assist.",
  //   blogContent: `
  //     <h1>Why Errand Services Are a Game Changer</h1>
  //     <p>
  //       <strong>1. Increased Productivity:</strong>  Delegating tasks like shopping, mailing, or picking up items can free up significant amounts of your time, allowing you to focus on what's truly important—be it your career, education, or quality time with loved ones..<br><br>
  //       <strong>2. Stress Reduction:</strong> Managing a full list of errands can be stressful, especially when balanced with other responsibilities. Using errand services through 100xHelper can help reduce this stress by handling these tasks for you.<br><br>
  //       <strong>3. Flexibility:</strong> With 100xHelper, you can arrange errand services around your schedule, ensuring that your tasks are completed when it suits you best. This flexibility is invaluable for those with unpredictable or busy schedules.<br><br>
  //     </p>
  //     <h2>How Errand Services on 100xHelper Work</h2>
  //     <p><strong>Simple and Transparent Process:</strong></p>
  //     <p>
  //       <strong>1. Post Your Task:</strong> Detail what errand you need help with, whether it's grocery shopping, parcel delivery, or something else. Specify any preferences or instructions that might help the helper meet your needs.<br><br>
  //       <strong>2. Receive Responses from Helpers:</strong> Helpers interested in completing your task will respond with their offers, allowing you to choose based on their rates and previous user ratings.<br><br>
  //       <strong>3. Select Your Helper:</strong> Choose the helper who best fits your needs based on their proposal and profile. This choice can be influenced by their proximity to the errand location, their availability, or their experience with similar tasks.<br>
  //     </p>
  //     <p>
  //       <strong>Secure and Efficient: </strong>Each step is conducted through the 100xHelper platform, ensuring that all transactions are secure and communication is clear. This process is designed to be efficient from posting the task to task completion.<br><br>
  //     </p>
  //     <h1>The Impact of Outsourcing Your Errands</h1>
  //     <p>Imagine a day where instead of running around the city, you're taking a well-deserved break or focusing on a project that matters to you. Outsourcing your errands doesn't just save time; it enhances your quality of life. It turns a potentially hectic day into one that's productive and calm. Clients who use 100xHelper for errand services often find they are more present in the moments that matter, leading to a more fulfilling daily life.</p>
  //     <h1>Conclusion</h1>
  //     <p>
  //       With 100xHelper, managing your daily tasks doesn’t have to be a burden. Our platform offers a convenient, flexible, and reliable way to handle your errands, giving you back the time you need to focus on what’s most important.
  //     </p>
  //     <p>
  //       <strong>Ready to simplify your life? Post your task on 100xHelper today and discover how easy it is to tick off your to-do list with the help of trusted helpers on our platform!
  //       </strong>
  //     </p>`,
  // },
  {
    id: "blog-2",
    title: "Simplify Laundry with 100xHelper Helpers",
    imagePath: "/assets/img/blogs-images/blog-3.jpg",
    blogOwnerName: "Shruti Gada",
    blogOwnerImage: "/assets/img/blogs-images/avatar3.png",
    blogDate: "15 August 2024",
    description:
      "Laundry can be a tedious and time-consuming task, especially in a bustling city like Berlin. Whether you're living in an apartment without a washing machine or you're simply too busy to keep up with the laundry, 100xHelper is here to make your life easier. Our platform connects you with trusted laundry helpers who can take care of everything from washing to folding, giving you more time to enjoy the things you love.",
    blogContent: `
      <h1>Why 100xHelper for Your Laundry Needs?</h1>
      <p>
        <strong>1. Convenience and Time-Saving:</strong>   Whether you're dealing with a lack of in-home laundry facilities or simply have a hectic schedule, 100xHelper offers a convenient solution. Our platform allows you to find reliable helpers who can manage your laundry tasks, from pick-up and drop-off to full-service washing and folding. This way, you can reclaim your time and focus on more important or enjoyable activities.<br><br>
        <strong>2. Trusted Helpers for Peace of Mind:</strong>  We understand that trust is crucial when it comes to handling your personal items. That's why 100xHelper connects you with trusted laundry helpers who have been carefully vetted. You can rest assured that your clothes are in good hands, whether it's your everyday wear or special fabrics that requir.<br><br>
        <strong>3. Tailored to Your Needs:</strong> Everyone's laundry needs are unique. Some people may need weekly assistance, while others might only require help during particularly busy periods. 100xHelper offers flexibility, allowing you to schedule services as needed. Whether it's a one-time service or a regular arrangement, our platform makes it easy to find the help you need.<br><br>
        <strong>4. Affordable and Transparent:</strong> Finding the right laundry help shouldn't break the bank. With 100xHelper you can get competitive pricing with no hidden fees. You can easily find and negotiate with helpers directly on our platform, ensuring that you get the service you need at a price that works for you.<br><br>
      </p>
      <h2>How It Works</h2>
      <p>Using 100xHelper to find a laundry helper is simple:</p>
      <p>
        <strong>1. Post Your Task:</strong> Describe your laundry needs, whether it's washing, drying, folding, ironing, or all of the above.<br><br>
        <strong>2. Helpers Reach Out to You:</strong>  Once your task is posted, helpers will review your request and reach out to you directly. You can then communicate with them to clarify details and make arrangements.<br><br>
        <strong>3. Choose the Best Fit:</strong> Review the responses and select the helper who best meets your requirements and budget. Schedule the service at your convenience.<br><br>
      </p>
      <h2>Make Laundry Day a Breeze with 100xHelper!</h2>
      <p>Why spend your weekends doing laundry when you can easily find trusted help? Sign up on 100xHelper today and discover how our platform can simplify your life. Let us take care of your laundry so you can spend more time doing what you love.</p>
      <h5><strong>Get Started Now!</strong></h5>`,
  },
  // {
  //   id: "blog-4",
  //   title: "Translation Services: Overcome Language Barriers",
  //   imagePath: "/assets/img/blogs-images/blog-4.jpg",
  //   blogOwnerName: "Tushar Dudhatra",
  //   blogOwnerImage: "/assets/img/blogs-images/avatar3.png",
  //   blogDate: "15 August 2024",
  //   description:
  //     "Navigating a new city and culture can be thrilling, but also challenging—especially when there's a language barrier. In Berlin, where German is the lingua franca, understanding and communicating effectively can be crucial for both personal and professional success. Here’s why and when you might need the specialized skills of a German translator, rather than relying on automated tools like Google Translator.",
  //   blogContent:`
  //     <h1>Finding the Right German Translator in Berlin</h1><br>
  //     <h4>Why Use a Professional German Translator?</h4>
  //     <p>
  //       <strong>Accuracy and Nuance:</strong> Automated translation tools are improving, but they often miss the nuances of language that can be critical in formal or complex interactions. A professional translator ensures that the subtleties and cultural nuances are correctly interpreted, which can be crucial in legal, medical, or business settings.<br><br>
  //       <strong>Confidentiality and Trust:</strong> For sensitive matters, professional translators provide the confidentiality that online tools cannot guarantee. This is particularly important for legal documents, medical records, or any personal information that requires discretion.<br><br>
  //       <strong>Localized Knowledge:</strong> Professional translators bring local knowledge that automated tools lack. This can include understanding local idioms, cultural references, or the bureaucratic specifics of the German administration, which can be indispensable for navigating local systems effectively.<br><br>
  //     </p>
  //     <h4>Essential Areas for Professional German Translation Services</h4>
  //     <p>
  //       <strong>Legal Proceedings:</strong> Understanding legal documents, contracts, or proceedings in court requires precise language. Misinterpretations can lead to serious consequences, making professional translation essential.<br><br>
  //       <strong>Medical Consultations: </strong> In healthcare, accurate communication can be a matter of safety. Professional translators help ensure that medical advice and patient concerns are clearly understood by all parties.<br><br>
  //       <strong>Business Negotiations:</strong> For those establishing or running a business, negotiations and contractual discussions benefit significantly from precise translation. This ensures that all parties are unequivocally clear about terms and conditions.<br><br>
  //       <strong>Academic and Research Work:</strong> When studying or participating in research in Berlin, professional translation can help in understanding complex academic materials or in communicating your research to a German-speaking audience.<br><br>
  //       <strong>Real Estate Transactions:</strong> Buying or renting property involves intricate documentation and negotiation, areas where professional translators can provide invaluable assistance to ensure that agreements are clear and legally binding.<br><br>
  //       <strong>Government and Immigration Documentation:</strong> Navigating government paperwork can be daunting. Professional translators can help with applications, interviews, and all forms of bureaucratic communication, ensuring that submissions are correctly completed and understood.<br><br>
  //     </p>
  //     <h2>Conclusion</h2>
  //     <p>Having access to a professional German translator can be your key to unlocking a smoother, more effective interaction in multiple facets of life. Whether it’s for navigating the complexities of a new city, settling into a new job, or handling legal matters, 100xHelper connects you with trusted German translators who can bridge the gap between languages and cultures.</p>
  //     <p><strong>Ready to ensure your communications are clear and professional?</strong> Find your German translator on 100xHelper now and experience the peace of mind that comes with knowing you're fully understood.</p>`
  // },
  {
    id: "blog-3",
    title: "Pet Care in Berlin: Find Help with 100xHelper",
    imagePath: "/assets/img/blogs-images/blog-5.jpg",
    blogOwnerName: "Sagar Patel",
    blogOwnerImage: "/assets/img/blogs-images/avatar3.png",
    blogDate: "15 August 2024",
    description:
      "Living in Berlin with a pet can enrich your life immensely, offering companionship and joy. However, managing pet care amidst busy schedules or unexpected travels can be challenging. That's where 100xHelper comes into play, offering a straightforward solution to connect you with trusted pet care providers across Berlin.",
    blogContent:`
      <h1>Why 100xHelper is Your Go-To for Pet Care</h1><br>
      <p>
        <strong>Tailored Pet Care Solutions:</strong> 100xHelper is not a pet care provider but a powerful resource for finding the right person to care for your pet. Whether you need someone for daily walks, overnight pet sitting, or even specialized care for a pet with health needs, our platform lets you specify your requirements and connect with a suitable helper.<br><br>
        <strong>Trusted Community:</strong> Trust is crucial when it comes to pet care. That’s why all helpers on the 100xHelper platform are members of a trusted community, where pet owners can review profiles, read reviews, and choose someone who meets their specific pet care standards.<br><br>
        <strong>Easy and Flexible Process:</strong> Here’s how 100xHelper helps you find the right pet care:<br><br>
        <strong>1. Post Your Task:</strong> Detail your pet care needs, including the type of services required and your pet’s specific needs.<br><br>
        <strong>2. Receive Responses:</strong> Helpers interested in your task will reach out to you. You can discuss their experience, your expectations, and everything in between.<br><br>
        <strong>3. Select Your Helper:</strong> Choose the helper who best fits your requirements and arrange the pet care as needed.<br><br>
      </p>
      <h4>Make Pet Care Stress-Free</h4>
      <p>
        Don’t let the search for pet care add stress to your life. With 100xHelper, you can find reliable helpers who can meet the diverse needs of your pet, whether it’s a routine walk or specialized attention. Sign up today and experience how easy pet care can be with the right support.
      </p>
      <h6>Find Perfect Caretaker For Your Pet Today</h6>`
  },
];

export const LearningPageContent = [
  {
    id: "helper-training",
    title: "Restaurant Helper and Waitstaff Training",
    imagePath: "/assets/img/blogs-images/training-thumbnail.png",
    blogOwnerName: "100xHelper",
    blogOwnerImage: "/assets/img/blogs-images/training-thumbnail.png",
    blogDate: "04 November 2024",
    description:
      "Finding trustworthy and efficient cleaning help in a bustling city like Berlin can be challenging, especially if you're new to the area. With 100xHelper, we've made it easier for you to connect with trusted cleaning professionals who can assist with everything from routine cleaning to special deep-cleaning projects.",
    blogContent: `
      <section class="hero">
        <div class="container">
            <h1 class="content-title" style="margin-bottom: 16px; color: #283350;">Hands-On Training for Restaurant Helper and Waitstaff Roles in Berlin</h1>
            <h5 class="content-caption" style="margin-bottom: 16px; line-height: 1.5;">Acquire the skills you need to secure part-time or full-time roles in the restaurant industry - boost your confidence and job prospects today!</h5>
            <a class="content-sign-up-btn" href="https://100xhelper-restaurant.eventbrite.de" target="_blank">Book Your Spot</a><br/>
            <p style="margin: 16px 0;"><b>For any queries, feel free to contact us via Email: <a href="mailto:training@100xhelper.de">training@100xhelper.de</a> or <a href="https://wa.me/4915167824246" target="_blank">WhatsApp: (+49) 151 6782 4246</a></b></p>
            <p style="margin: 16px 0;">Not available for the current session? Show your interest, and we'll notify you about the next training session.</p>
            <a class="content-notify-btn" href="https://forms.gle/ivMKjNAukFxxojZw5" target="_blank">Notify Me for Next Training</a><br/>
        </div>
    </section>

    <!-- Course Overview -->
    <section class="section bg-light">
        <div class="container">
            <h2 style="margin: 16px 0 8px 0;">Course Overview</h2>
            <p>Are you looking to gain practical experience in Berlin's gastronomy industry while studying or transitioning to a new career? This training is for English-speaking newcomers, immigrants, and students who want the skills needed to confidently work in any restaurant setting.</p>
            <h3 style="margin-bottom: 16px;">Training Highlights</h3>
            <ul style="list-style: square; padding-left: 30px;">
                <li style="margin-bottom: 4px;">Hands-on training in an active restaurant environment</li>
                <li style="margin-bottom: 4px;">Covers kitchen operations, bar operations, and front-of-house service</li>
                <li style="margin-bottom: 4px;">Affordable training fee: <strong>€30</strong> (limited time offer)</li>
                <li style="margin-bottom: 4px;">Gain skills to enhance your prospects of finding a job in the restaurant industry</li>
                <li style="margin-bottom: 4px;">Develop skills that impress guests and boost your chances of earning generous tips</li>
            </ul>
        </div>
    </section>

    <!-- Why Choose This Training -->
    <section class="section">
        <div class="container">
            <h2 style="margin-bottom: 16px;">Why Choose This Training?</h2>
            <ul style="list-style: square; padding-left: 30px;">
                <li style="margin-bottom: 4px;"><strong><b>Hands-On Gastronomy Experience:</b></strong> Train alongside experienced professionals in an active restaurant setting.</li>
                <li style="margin-bottom: 4px;"><strong><b>Affordable and Effective:</b></strong> Benefit from a limited-time offer of <strong>€30</strong> until 20th November. Take advantage of this comprehensive course that covers kitchen, bar, and front-of-house skills.</li>
                <li style="margin-bottom: 4px;"><strong><b>Universal Restaurant Skills:</b></strong> Master skills that are valuable across all types of restaurant environments, no matter the cuisine.</li>
                <li style="margin-bottom: 4px;"><strong><b>Gain a Competitive Edge:</b></strong> Develop practical skills that set you apart from others and make you a preferred candidate for restaurant jobs.</li>
                <li style="margin-bottom: 4px;"><strong><b>Limited Slots Available:</b></strong> We only have 10 spots per session, and they fill up fast. Secure your spot before it’s too late!</li>
            </ul>
        </div>
    </section>

    <!-- Curriculum Details -->
    <section class="section bg-light">
        <div class="container">
            <h3 style="margin-bottom: 16px;">Training Modules</h3>
            <ol style="list-style: square; padding-left: 30px;">
                <li style="margin-bottom: 4px;"><strong><b>Kitchen Operations:</b></strong> Food safety, hygiene practices, and effective assistance in a busy kitchen.</li>
                <li style="margin-bottom: 4px;"><strong><b>Front-of-House Operations:</b></strong> Guest reception and table service.</li>
                <li style="margin-bottom: 4px;"><strong><b>Bar Operations:</b></strong> Setting up the bar, restocking, and basic drink preparation.</li>
                <li style="margin-bottom: 4px;"><strong><b>Customer Interaction:</b></strong> Providing professional hospitality service and effective communication with guests.</li>
            </ol>
            <p><strong><b>Session Duration:</b></strong> Up to 4 hours of practical, hands-on training.</p>
        </div>
    </section>

    <!-- Training Details -->
    <section class="section">
        <div class="container">
            <h2 style="margin-bottom: 16px;">Training Details</h2>
            <p style="margin-bottom: 4px; padding-left: 16px;"><strong><b>Training Venue:</b></strong> Köpenicker Str. 159, 10997 Berlin.</p>
            <p style="margin-bottom: 4px; padding-left: 16px;"><strong><b>Upcoming Slots:</b></strong> Sunday, 24th November, 07:30 AM - 11:30 AM</p>
            <p style="padding-left: 16px;"><strong><b>Training Fee:</b></strong> <del>€50</del> <strong>€30</strong> (limited-time offer until 20th November; after that, the training will cost <strong>€50</strong>)</p>
            <p style="margin-top: 16px;"><b>For questions or more information, reach out to us via Email: <a href="mailto:training@100xhelper.de">training@100xhelper.de</a> or <a href="https://wa.me/4915167824246" target="_blank">WhatsApp: (+49) 151 6782 4246</a></b></p>
        </div>
    </section>

    <!-- What You Will Get -->
    <section class="section bg-light">
        <div class="container">
            <h2 style="margin-bottom: 16px;">What You Will Get</h2>
            <ul style="list-style: square; padding-left: 30px;">
                <li style="margin-bottom: 4px;"><strong><b>Certification:</b></strong> A certificate of completion to validate your skills.</li>
                <li style="margin-bottom: 4px;"><strong><b>Skills for Immediate Employment:</b></strong> Gain practical experience that prepares you for roles related to kitchen operations, front-of-house service, and bar operations.</li>
            </ul>
        </div>
    </section>

    <!-- Meet the Trainers -->
    <section class="section">
        <div class="container">
            <h2 style="margin-bottom: 16px;">Meet the Trainers</h2>
            <ul style="list-style: square; padding-left: 30px;">
                <li style="margin-bottom: 4px;"><strong>Founder of 100xHelper:</strong> Moderating the session.</li>
                <li style="margin-bottom: 4px;"><strong>Restaurant Manager and Owner:</strong> Training participants in front-of-house operations, including bar operations and guest services.</li>
                <li style="margin-bottom: 4px;"><strong>Head Chef:</strong> Hands-on training in kitchen operations.</li>
            </ul>
        </div>
    </section>

    <!-- Sign Up Now -->
    <section class="section bg-light text-center" style="padding: 50px 20px;">
        <div class="container">
            <h2>Book Your Spot Today</h2>
            <p>Secure Your Spot for €30 (limited-time offer until 20th November)</p>
            <a class="content-sign-up-btn" href="https://100xhelper-restaurant.eventbrite.de" target="_blank">Book Your Spot</a><br/>
            <p style="margin-top: 16px;"><em>Only 10 spots available per session to ensure personalized training - hurry, spots are filling up fast!</em></p>
        </div>
    </section>

    <!-- FAQs -->
    <section class="section">
        <div class="container">
            <h2 style="margin-bottom: 16px;">FAQs</h2>
            <p><strong>Is the training suitable for complete beginners?</strong> <br> Yes! No previous experience is needed.</p>
            <p><strong>What language is the training conducted in?</strong> <br> The training will be in English.</p>
            <p><strong>Will I get a job after the training?</strong> <br> While we cannot guarantee jobs, this training will significantly boost your chances of securing a role in the hospitality industry.</p>
        </div>
    </section>`,
  }
];
