import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import PagesHeader from "../../../shared/Navbar/PagesHeader";
import { Avatar, Box, Typography } from "@mui/material";
import Footer from "../../../shared/Footer";
import "./training.css";
import { LearningPageContent } from "../../../shared/Constants/constants";

const TrainingPage = () => {
  const [fullBlogContent, setFullBlogContent] = useState();

  useEffect(() => {
      const blogId = 'helper-training';
      if (!isEmpty(LearningPageContent)) {
        const blogData = LearningPageContent.find((item) => item.id === blogId);
        setFullBlogContent(blogData);
      }
  });
  return (
    <>
      <div id="page" className="theia-exception">
        <PagesHeader />

        <main className="bg-light-blue main-blog-wrapper">
          <Box className="container r" sx={{ marginTop: "60px" }}>
            {!isEmpty(fullBlogContent) && (
              <Box
                sx={{ width: "100%" }}
                className="d-flex align-items-start justify-content-start flex-column"
              >
                <Box className="full-blog-image-container">
                  <Avatar
                    src={fullBlogContent.imagePath}
                    variant="square"
                    sx={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </main>
        <Box className="container" sx={{ marginBottom: "10px" }}>
          <Box className={"blog-content-wrapper"}>
            {!isEmpty(fullBlogContent) && (
              <div
                dangerouslySetInnerHTML={{
                  __html: fullBlogContent.blogContent,
                }}
              />
            )}
          </Box>
        </Box>
        <Footer />
      </div>
    </>
  );
};

export default TrainingPage;
