import React, { useState } from "react";
import SendIcon from "@mui/icons-material/Send";

const Compose = ({ handleSendMessage }) => {
  const [message, setMessage] = useState("");

  return (
    <div className="chat-input-section p-3 p-lg-4 border-top mb-0">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSendMessage(message, "TEXT");
          setMessage("");
        }}
      >
        <div className="row g-0">
          <div className="col">
            <input
              type="text"
              className="form-control form-control-lg bg-light border-light"
              placeholder="Enter Message..."
              onChange={(e) => setMessage(e.target.value)}
              value={message}
            />
          </div>
          <div className="col-auto">
            <div className="chat-input-links ms-md-2 me-md-0">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  <button
                    type="submit"
                    className="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light"
                    disabled={message === ""}
                  >
                    <SendIcon />
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Compose;
