import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputFields from "../../../shared/InputFieldsWithLabels";
import PasswordFields from "../../../shared/InputFieldsWithLabels/PasswordFields";
import {
  forgotPassword,
  verifyForgotPassword,
} from "../../../helper/APIs/Auth";
import { toast } from "react-toastify";
import {
  CURRENT_YEAR,
  getErrorMessage,
} from "../../../shared/Constants/util-functions";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [formValues, setFormValues] = useState({
    otp: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitStepOne = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const submitData = { email, otpType: "FORGOT_PWD" };
      const res = await forgotPassword(submitData);

      if (res.success) {
        setLoading(false);
        setStep(2);
        toast.success("OTP sent to the registered email.");
      }
    } catch (err) {
      setLoading(false);
      const errorMessage = getErrorMessage(err);
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  const handleSubmitStepTwo = async (e) => {
    e.preventDefault();
    setLoading(true);

    const otpRegex = /^[0-9]{0,6}$/;

    try {
      if (!otpRegex.test(formValues.otp)) {
        toast.error("Please enter only numbers in OTP.");
        throw new Error("Please enter only numbers in OTP.");
      }

      if (formValues.newPassword !== formValues.confirmNewPassword) {
        toast.error("Passwords must match. Please ensure both fields are the same.");
        throw new Error("Passwords don't match.");
      }

      const submitData = { userEmail: email, ...formValues };
      const res = await verifyForgotPassword(submitData);

      if (res.success) {
        setLoading(false);
        toast.success("Password updated successfully.");
        navigate("/login");
      }
    } catch (err) {
      setLoading(false);
      const errorMessage = getErrorMessage(err);
      if (errorMessage) {
        toast.error(errorMessage);
      }
    }
  };

  return (
    <>
      {step === 1 && (
        <div id="login_bg">
          <div id="login">
            <aside>
              <figure>
                <Link to="/">
                  <img
                    src="assets/img/LOGO/100xhelper/100xhelper.svg"
                    width="100"
                    height="40"
                    alt=""
                    className="logo_sticky object-fit-cover"
                  />
                </Link>
              </figure>
              <form className="was-validated" onSubmit={handleSubmitStepOne}>
                <InputFields
                  label="Email"
                  type="email"
                  id="email"
                  iconClass="fa fa-envelope"
                  onChange={({ target: { value } }) => setEmail(value)}
                  required={true}
                  defaultValue={email}
                />
                <button
                  type="submit"
                  className="btn_1 rounded full-width"
                  disabled={loading}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm mx-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}{" "}
                  Get Otp Now
                </button>
                <div className="copy">© {CURRENT_YEAR} 100xHelper</div>
              </form>
            </aside>
          </div>
        </div>
      )}

      {step === 2 && (
        <div id="login_bg">
          <div id="login">
            <aside>
              <figure>
                <Link to="/">
                  <img
                    src="assets/img/LOGO/100xhelper/100xhelper.svg"
                    width="100"
                    height="40"
                    alt=""
                    className="logo_sticky object-fit-cover"
                  />
                </Link>
              </figure>
              <form className="was-validated" onSubmit={handleSubmitStepTwo}>
                <InputFields
                  label="OTP"
                  type="text"
                  id="otp"
                  name="otp"
                  iconClass="fa fa-keyboard-o"
                  onChange={handleChange}
                  required={true}
                  defaultValue={formValues.otp}
                />
                <PasswordFields
                  label="Your password"
                  type="password"
                  id="password1"
                  name="newPassword"
                  iconClass="fa fa-lock"
                  onChange={handleChange}
                  defaultValue={formValues.newPassword}
                  required={false}
                />
                <PasswordFields
                  label="Confirm password"
                  type="password"
                  id="password2"
                  name="confirmNewPassword"
                  iconClass="fa fa-lock"
                  onChange={handleChange}
                  defaultValue={formValues.confirmNewPassword}
                  required={false}
                />
                <button
                  type="submit"
                  className="btn_1 rounded full-width"
                  disabled={loading}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm mx-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Submit
                </button>
                <div className="copy">
                  Copyright © {new Date().getFullYear()}&nbsp; 100xHelper <br />
                  All Rights Reserved
                </div>
              </form>
            </aside>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
