const config = {
  TIMEZONE: "America/Sao_Paulo",
};

const envData = (ENV, local, test, production) => {
  if (ENV === "local") {
    return local;
  } else if (ENV === "test") {
    return test;
  } else if (ENV === "production") {
    return production;
  } else {
    console.log(new Error("Something went wrong with credentials"));
  }
};

export const ENV_TYPE = "production"; // local // test // production

export const BASE_URL = envData(
  ENV_TYPE,
  "https://dev-api.samaylekha.eu/",
  "https://dev-api.samaylekha.eu/",
  "https://api.samaylekha.eu/"
);
export const GOOGLE_CLIENT_ID = envData(
  ENV_TYPE,
  "dummy-client-id-for-local",
  "dummy-client-id-for-dev",
  "1063417211180-qn8ui5kjsmrbgochqa4ris7mjj8rvt0l.apps.googleusercontent.com"
);

export default config;
export const GOOGLE_PLACE_KEY = "AIzaSyCPzdHQ0ITIXKke3UlbDwmn32eUooRtE5U";

// Client ID: 1063417211180-qn8ui5kjsmrbgochqa4ris7mjj8rvt0l.apps.googleusercontent.com
// Client Secret: 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
// {
//   "Ca": "105023655983308702950",
//   "xc": {
//     "token_type": "Bearer",
//     "access_token": "ya29.a0AfB_byAEU3tKMwCkGGi6lzVHY1iUApHhsRJXF-Q1ggH-oM9qFN39pFAvajqiubJPMzLWXvf3TbK6Ein623FB7vlLuH25fsiUu62Mwg1lTypBSM6IqedwcvoKn83kSaRg1cxvDwwvxTnvClJhcYsVaWDJLMi3t9ijNSAupAaCgYKAU8SARISFQHsvYlsuElzqJir-HjzswV5NsUHrg0173",
//     "scope": "email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email",
//     "login_hint": "AJDLj6JUa8yxXrhHdWRHIV0S13cAl4dm3CjWi2GnrxAhfxx-T7pGrweNznHdo_n9AnkA6xnGPaTRZ9pq4ploQcGpnV064ysdqA",
//     "expires_in": 3599,
//     "id_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImM3ZTExNDEwNTlhMTliMjE4MjA5YmM1YWY3YTgxYTcyMGUzOWI1MDAiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNDQwMTIyNTExNDg0LXUwNDlvZG1pMDNvNG1udXBkaGIxZm9vM3Jzc3A2cDFxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNDQwMTIyNTExNDg0LXUwNDlvZG1pMDNvNG1udXBkaGIxZm9vM3Jzc3A2cDFxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA1MDIzNjU1OTgzMzA4NzAyOTUwIiwiZW1haWwiOiJkZW5pc2hrdW5hZGl5YS53b3JrQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiclE0d3o2SndIOFZfZVVZT0theVZwQSIsIm5iZiI6MTY5MzMxMTUzNSwibmFtZSI6IkRlbmlzaCBLdW5hZGl5YSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQWNIVHRkVTg1TjR0Wk1zNkxnSWFBSDgycWN6c0RKc3lCYnRyV3N3UXpwVUhaSmM9czk2LWMiLCJnaXZlbl9uYW1lIjoiRGVuaXNoIiwiZmFtaWx5X25hbWUiOiJLdW5hZGl5YSIsImxvY2FsZSI6ImVuIiwiaWF0IjoxNjkzMzExODM1LCJleHAiOjE2OTMzMTU0MzUsImp0aSI6ImZmZjE3MmNhYTQzMTBhYzc0M2EzOGRmODk1NDQ3YmRmZTM4NjAyZjgifQ.OoysKf91cNBUBunrS4D_Y-CeY75ocoYWoBxj_Nkh85MWUix9LoAGdJocAYunLqpmaiCYWfC_OeWNBEfgaVyq2tX0ySVpM9c17v71Z7huBhd-EIgSrvv2gZIaexD2JeWXT5WpwHywm5lf8mmmCeTYRYcDKkcOPzHb2JbfjoKMNdEHtCZIeiEwaoFpNq_y7ESpXYqGVIqvncHsDHOgV3fl2zVeieX1KLffMpZVxUY8OEd6LFHYH6R09ptbX24bg35sz-JPMEDHq2FMzslUzWgL97xnIDU1DsyfMOirD_ciFBNdFI_FrZbU2fFZYYYaG9E85gq1gWlAGByT0dkPMXFR6A",
//     "session_state": {
//       "extraQueryParams": {
//         "authuser": "0"
//       }
//     },
//     "first_issued_at": 1693311835710,
//     "expires_at": 1693315434710,
//     "idpId": "google"
//   },
//   "wt": {
//     "NT": "105023655983308702950",
//     "Ad": "Denish Kunadiya",
//     "rV": "Denish",
//     "uT": "Kunadiya",
//     "hK": "https://lh3.googleusercontent.com/a/AAcHTtdU85N4tZMs6LgIaAH82qczsDJsyBbtrWswQzpUHZJc=s96-c",
//     "cu": "denishkunadiya.work@gmail.com"
//   },
//   "googleId": "105023655983308702950",
//   "tokenObj": {
//     "token_type": "Bearer",
//     "access_token": "ya29.a0AfB_byAEU3tKMwCkGGi6lzVHY1iUApHhsRJXF-Q1ggH-oM9qFN39pFAvajqiubJPMzLWXvf3TbK6Ein623FB7vlLuH25fsiUu62Mwg1lTypBSM6IqedwcvoKn83kSaRg1cxvDwwvxTnvClJhcYsVaWDJLMi3t9ijNSAupAaCgYKAU8SARISFQHsvYlsuElzqJir-HjzswV5NsUHrg0173",
//     "scope": "email profile https://www.googleapis.com/auth/userinfo.profile openid https://www.googleapis.com/auth/userinfo.email",
//     "login_hint": "AJDLj6JUa8yxXrhHdWRHIV0S13cAl4dm3CjWi2GnrxAhfxx-T7pGrweNznHdo_n9AnkA6xnGPaTRZ9pq4ploQcGpnV064ysdqA",
//     "expires_in": 3599,
//     "id_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImM3ZTExNDEwNTlhMTliMjE4MjA5YmM1YWY3YTgxYTcyMGUzOWI1MDAiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNDQwMTIyNTExNDg0LXUwNDlvZG1pMDNvNG1udXBkaGIxZm9vM3Jzc3A2cDFxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNDQwMTIyNTExNDg0LXUwNDlvZG1pMDNvNG1udXBkaGIxZm9vM3Jzc3A2cDFxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA1MDIzNjU1OTgzMzA4NzAyOTUwIiwiZW1haWwiOiJkZW5pc2hrdW5hZGl5YS53b3JrQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiclE0d3o2SndIOFZfZVVZT0theVZwQSIsIm5iZiI6MTY5MzMxMTUzNSwibmFtZSI6IkRlbmlzaCBLdW5hZGl5YSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQWNIVHRkVTg1TjR0Wk1zNkxnSWFBSDgycWN6c0RKc3lCYnRyV3N3UXpwVUhaSmM9czk2LWMiLCJnaXZlbl9uYW1lIjoiRGVuaXNoIiwiZmFtaWx5X25hbWUiOiJLdW5hZGl5YSIsImxvY2FsZSI6ImVuIiwiaWF0IjoxNjkzMzExODM1LCJleHAiOjE2OTMzMTU0MzUsImp0aSI6ImZmZjE3MmNhYTQzMTBhYzc0M2EzOGRmODk1NDQ3YmRmZTM4NjAyZjgifQ.OoysKf91cNBUBunrS4D_Y-CeY75ocoYWoBxj_Nkh85MWUix9LoAGdJocAYunLqpmaiCYWfC_OeWNBEfgaVyq2tX0ySVpM9c17v71Z7huBhd-EIgSrvv2gZIaexD2JeWXT5WpwHywm5lf8mmmCeTYRYcDKkcOPzHb2JbfjoKMNdEHtCZIeiEwaoFpNq_y7ESpXYqGVIqvncHsDHOgV3fl2zVeieX1KLffMpZVxUY8OEd6LFHYH6R09ptbX24bg35sz-JPMEDHq2FMzslUzWgL97xnIDU1DsyfMOirD_ciFBNdFI_FrZbU2fFZYYYaG9E85gq1gWlAGByT0dkPMXFR6A",
//     "session_state": {
//       "extraQueryParams": {
//         "authuser": "0"
//       }
//     },
//     "first_issued_at": 1693311835710,
//     "expires_at": 1693315434710,
//     "idpId": "google"
//   },
//   "tokenId": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImM3ZTExNDEwNTlhMTliMjE4MjA5YmM1YWY3YTgxYTcyMGUzOWI1MDAiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiNDQwMTIyNTExNDg0LXUwNDlvZG1pMDNvNG1udXBkaGIxZm9vM3Jzc3A2cDFxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiNDQwMTIyNTExNDg0LXUwNDlvZG1pMDNvNG1udXBkaGIxZm9vM3Jzc3A2cDFxLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTA1MDIzNjU1OTgzMzA4NzAyOTUwIiwiZW1haWwiOiJkZW5pc2hrdW5hZGl5YS53b3JrQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiclE0d3o2SndIOFZfZVVZT0theVZwQSIsIm5iZiI6MTY5MzMxMTUzNSwibmFtZSI6IkRlbmlzaCBLdW5hZGl5YSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS9BQWNIVHRkVTg1TjR0Wk1zNkxnSWFBSDgycWN6c0RKc3lCYnRyV3N3UXpwVUhaSmM9czk2LWMiLCJnaXZlbl9uYW1lIjoiRGVuaXNoIiwiZmFtaWx5X25hbWUiOiJLdW5hZGl5YSIsImxvY2FsZSI6ImVuIiwiaWF0IjoxNjkzMzExODM1LCJleHAiOjE2OTMzMTU0MzUsImp0aSI6ImZmZjE3MmNhYTQzMTBhYzc0M2EzOGRmODk1NDQ3YmRmZTM4NjAyZjgifQ.OoysKf91cNBUBunrS4D_Y-CeY75ocoYWoBxj_Nkh85MWUix9LoAGdJocAYunLqpmaiCYWfC_OeWNBEfgaVyq2tX0ySVpM9c17v71Z7huBhd-EIgSrvv2gZIaexD2JeWXT5WpwHywm5lf8mmmCeTYRYcDKkcOPzHb2JbfjoKMNdEHtCZIeiEwaoFpNq_y7ESpXYqGVIqvncHsDHOgV3fl2zVeieX1KLffMpZVxUY8OEd6LFHYH6R09ptbX24bg35sz-JPMEDHq2FMzslUzWgL97xnIDU1DsyfMOirD_ciFBNdFI_FrZbU2fFZYYYaG9E85gq1gWlAGByT0dkPMXFR6A",
//   "accessToken": "ya29.a0AfB_byAEU3tKMwCkGGi6lzVHY1iUApHhsRJXF-Q1ggH-oM9qFN39pFAvajqiubJPMzLWXvf3TbK6Ein623FB7vlLuH25fsiUu62Mwg1lTypBSM6IqedwcvoKn83kSaRg1cxvDwwvxTnvClJhcYsVaWDJLMi3t9ijNSAupAaCgYKAU8SARISFQHsvYlsuElzqJir-HjzswV5NsUHrg0173",
//   "profileObj": {
//     "googleId": "105023655983308702950",
//     "imageUrl": "https://lh3.googleusercontent.com/a/AAcHTtdU85N4tZMs6LgIaAH82qczsDJsyBbtrWswQzpUHZJc=s96-c",
//     "email": "denishkunadiya.work@gmail.com",
//     "name": "Denish Kunadiya",
//     "givenName": "Denish",
//     "familyName": "Kunadiya"
//   }
// }
