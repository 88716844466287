import { Delete } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoneIcon from "@mui/icons-material/Done";
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "Title",
    numeric: false,
    disablePadding: false,
    label: "Title",
  },
  {
    id: "Category",
    numeric: true,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },

  {
    id: "Action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];
const useStyles = makeStyles({
  tableBody: {
    "& > :not(:last-child)": {
      borderBottom: "25px solid red",
    },
  },
  root: {
    "& .MuiTable-root, .MuiTableBody-root, .MuiPaper-root": {
      backgroundColor: "transparent !important",
      boxShadow: "unset !important",
    },
    "& .MuiTableRow-root": {
      backgroundColor: "#ffff !important",
      //   marginBottom: "10px !important",
      // borderBottom: "5px solid #F8F5FA",
    },
    "& .MuiTableCell-root MuiTableCell-head": {
      fontSize: "21px",
      fontFamily: "Poppins",
      color: "#464A53 !important",
    },
    "& .MuiTableCell-root MuiTableCell-body": {
      fontSize: "16px",
      fontFamily: "Poppins",
    },
  },
});
function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell, index) => (
          <TableCell
            align={"left"}
            sx={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "#809FB8 !important",
            }}
            key={index}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTable = ({
  tableRows,
  handleDeleteListing,
  handleTaskCompleted,
}) => {
  const classes = useStyles();

  const [rows, setRows] = React.useState(tableRows);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  useEffect(() => {
    setRows(tableRows);
  }, [tableRows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const totalPage = Math.ceil(rows.length / rowsPerPage);
  const mapPage = Array.from(Array(totalPage).keys());

  const navigate = useNavigate();

  const handleEdit = (row) => {
    navigate(`/edit-task/${row.id}`);
  };
  const handleViewListing = (row) => {
    navigate(`/tasks/${row.id}`);
  };

  return (
    <Box sx={{ width: "100%", padding: 0 }} className={classes.root}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody py={2}>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow
                      style={{
                        marginBottom: "10px !important",
                      }}
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell
                        align="left"
                        style={{
                          fontSize: "0.9rem",
                        }}
                      >
                        <div
                          onClick={() => handleViewListing(row)}
                          style={{ color: "#283350", cursor: "pointer" }}
                        >
                          {row?.title}
                        </div>
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          wordWrap: "break-word",
                          width: "20%",
                          fontSize: "0.9rem",
                        }}
                      >
                        {row.categoryName}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: "0.9rem",
                        }}
                      >
                        {row?.listingStatus === "OPEN" ? (
                          <Chip
                            label={row?.listingStatus}
                            icon={<AccessTimeIcon />}
                          />
                        ) : row?.listingStatus === "COMPLETED" ? (
                          <Chip
                            sx={{ background: "green", color: "#fff" }}
                            label={row?.listingStatus}
                            icon={
                              <DoneIcon sx={{ color: "#fff !important" }} />
                            }
                          />
                        ) : (
                          <Chip
                            label={row?.listingStatus}
                            sx={{ background: "#ffc107", color: "#222" }}
                            // icon={<Delete sx={{ color: "#fff !important" }} />}
                            variant="outlined"
                          />
                        )}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          //   color: "#3D4655",

                          fontSize: "0.9rem",
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <button
                            className="btn_view mx-1"
                            onClick={() => handleViewListing(row)}
                          >
                            View
                          </button>
                          <button
                            // className="btn_edit mx-1"
                            className={`${
                              ["DEACTIVATED", "COMPLETED", "ASSIGNED"].includes(
                                row?.listingStatus
                              )
                                ? "cursor-disabled opacity--50"
                                : ""
                            } btn_edit mx-1 `}
                            onClick={() => handleEdit(row)}
                            disabled={["DEACTIVATED", "COMPLETED", "ASSIGNED"].includes(
                              row?.listingStatus
                            )}
                          >
                            Edit
                          </button>
                          {["DEACTIVATED", "COMPLETED", "ASSIGNED"].includes(
                            row?.listingStatus
                          ) ? (
                            <button
                              className="btn_delete  mx-1 cursor-disabled opacity--50"
                              // className="btn_reactivate mx-1"
                              // onClick={() => navigate(`/edit-task/${row.id}`)}
                            >
                              {/* Later on change below name to Repost */}
                              Deactivate
                            </button>
                          ) : (
                            <button
                              className="btn_delete  mx-1"
                              onClick={() => handleDeleteListing(row.id)}
                            >
                              Deactivate
                            </button>
                          )}

                          {row?.listingStatus === "COMPLETED" ? (
                            <button
                              className={`btn_reactivate mx-1 `}
                              onClick={() => navigate(`/edit-task/${row.id}`)}
                            >
                              Repost
                            </button>
                          ) : (
                            <button
                              className={`${
                                ["DEACTIVATED", "COMPLETED"].includes(
                                  row?.listingStatus
                                )
                                  ? "cursor-disabled opacity--50"
                                  : ""
                              } btn_reactivate mx-1 `}
                              onClick={() => handleTaskCompleted(row.id)}
                              disabled={["DEACTIVATED", "COMPLETED"].includes(
                                row?.listingStatus
                              )}
                            >
                              Complete
                            </button>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <>
          <TablePagination
            rowsPerPageOptions={[10]}
            labelRowsPerPage={"pages:"}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Box display="flex" justifyContent="flex-end" alignItems="center">
            {mapPage.map((obj, i) => {
              const activePageColor = i === page ? "#283350" : "#fff";
              const activePageFontColor = i === page ? "#fff" : "#ABAFB3";
              const pageNumber = i + 1;

              if (
                pageNumber === page ||
                pageNumber === page + 1 ||
                pageNumber === page + 2 ||
                pageNumber === totalPage
              ) {
                return (
                  <Box
                    sx={{
                      textAlign: "right",
                      background: activePageColor,
                      padding: "14px 17px",
                      marginRight: "4px",
                      borderRadius: "50%",
                      fontWeight: 700,
                      color: activePageFontColor,
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleChangePage(e, i)}
                    key={i}
                  >
                    {pageNumber < 10 ? "0" : ""}
                    {pageNumber}
                  </Box>
                );
              } else if (
                pageNumber === page + 3 &&
                pageNumber !== totalPage - 1
              ) {
                return (
                  <Box
                    sx={{
                      textAlign: "right",
                      padding: "14px 17px",
                      marginRight: "4px",
                      fontWeight: 700,
                      color: "#ABAFB3",
                    }}
                  >
                    ...
                  </Box>
                );
              } else if (
                pageNumber === totalPage - 1 &&
                pageNumber !== page + 3
              ) {
                return (
                  <Box
                    sx={{
                      textAlign: "right",
                      background: "#fff",
                      padding: "14px 17px",
                      marginRight: "4px",
                      borderRadius: "50%",
                      fontWeight: 700,
                      color: "#ABAFB3",
                    }}
                  >
                    {pageNumber < 10 ? "0" : ""}
                    {pageNumber}
                  </Box>
                );
              }
              return null;
            })}
          </Box>
        </>
      </Paper>
    </Box>
  );
};

export default EnhancedTable;
