import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const ChatHeader = ({ selectedUser, setShowProfile }) => {
  const loginReducer = useSelector(
    (state) => state?.loginReducer?.user?.data?.userProfile
  );
  const loggedInUser = loginReducer.id;

  return (
    <div className="p-3 p-lg-4 border-bottom user-chat-topbar">
      <div className="row align-items-center">
        <div className="col-sm-8 col-12">
          <div className="d-flex align-items-center">
            <div className="d-block d-lg-none me-2 ms-0">
              <Link
                to={"/chat"}
                className="user-chat-remove text-muted font-size-16 p-2"
              >
                <ArrowBackIcon
                  style={{ fontSize: "16px" }}
                  className="rounded-circle avatar-xs"
                />
              </Link>
            </div>
            <div className="me-3 ms-0">
              <Avatar
                className={"cursor-pointer"}
                onClick={() => setShowProfile(true)}
                src={
                  loggedInUser === selectedUser?.chatInitiator?.userProfileId
                    ? selectedUser?.listingOwner?.userProfileImageUrl
                    : selectedUser?.chatInitiator?.userProfileImageUrl
                }
              />
            </div>
            <div className="flex-grow-1 overflow-hidden">
              <h5 className="font-size-16 mb-0 text-truncate">
                <Link href="#" className="text-reset user-profile-show">
                  {loggedInUser === selectedUser?.chatInitiator?.userProfileId
                    ? selectedUser?.listingOwner?.userDisplayName
                    : selectedUser?.chatInitiator?.userDisplayName}
                </Link>{" "}
                <i className="ri-record-circle-fill font-size-10 text-success d-inline-block ms-1"></i>
              </h5>
              <Link to={`/tasks/${selectedUser?.listingInfo?.listingId}`}>
                <p
                  className="text-truncate font-size-14 mb-0 "
                  style={{ fontWeight: "bold", color: "black" }}
                >
                  {selectedUser?.listingInfo?.title || ""}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;
